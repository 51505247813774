import dynamic from 'next/dynamic'
import { Inter } from 'next/font/google'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Router from 'next/router'
import Script from 'next/script'
import NProgress from 'nprogress'
import PropTypes, { shape } from 'prop-types'
import { useEffect, useMemo } from 'react'

import '../style.css'
import { getShopOrganizationJson } from '@sholdi/shared/lib/jsonld'

import SessionProvider from '@sholdi/providers/Session'
import { SettingsProvider } from '@sholdi/providers/Settings'
import UrqlProvider from '@sholdi/providers/UrqlProvider'

import useCatch from '@sholdi/hooks/useCatch'

import getEnv from '@sholdi/shared/lib/env/getEnv'
import setEnvVariables from '@sholdi/shared/lib/env/setEnvVariables'

const Toast = dynamic(() => import('@sholdi/toast/Toast'))

NProgress.configure({ showSpinner: false })

Router.events.on('routeChangeStart', () => {
  NProgress.start()
})
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const inter = Inter({
  subsets: ['latin'],
  weight: ['300', '400', '500', '600', '700'],
  display: 'swap',
  preload: true,
  variable: '--font-inter',
})

const MyApp = ({
  Component,
  pageProps = {
    lng: 'bs',
    lngDict: {},
    settings: {
      currency: {
        code: 'BAM',
        symbol: 'KM',
      },
    },
    environmentVariables: {},
  },
}) => {
  const { settings, environmentVariables } = pageProps

  setEnvVariables(environmentVariables)

  const router = useRouter()
  const { asPath, query } = router
  // const { locale } = router
  useEffect(() => {
    document.body.className = `${inter.variable} `
  })

  const getLayout = Component.getLayout || (page => page)

  const URL = settings?.appUrl || getEnv('NEXT_PUBLIC_APP_URL')
  const websiteJson = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: URL,
    potentialAction: {
      '@type': 'SearchAction',
      target: `${URL}/search?q={search_term_string}`,
      'query-input': 'required name=search_term_string',
    },
  }

  useCatch({ asPath, query })

  const themeVariables = settings?.theme || {}

  const organizationJson = useMemo(() => {
    if (!settings?.shop) return {}
    return getShopOrganizationJson(settings.shop)
  }, [settings])

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, minimal-ui" />
      </Head>
      <Script
        id="jsonld-breadcrumb"
        type="application/ld+json"
        defer
        dangerouslySetInnerHTML={{ __html: JSON.stringify(websiteJson) }}
      />
      <Script
        id="jsonld-organization"
        type="application/ld+json"
        defer
        dangerouslySetInnerHTML={{ __html: JSON.stringify(organizationJson) }}
      />
      {!!pageProps.scripts?.length &&
        pageProps.scripts.map(script => (
          <Script
            key={script}
            id={script.id}
            type={script.type}
            dangerouslySetInnerHTML={{ __html: script.content }}
          />
        ))}
      <SessionProvider
        refetchInterval={5 * 60}
        // Re-fetches session when window is focused
        refetchOnWindowFocus={true}
      >
        <UrqlProvider pageProps={pageProps}>
          {/* <Fingerprint asPath={asPath} query={query} userId={null} /> */}
          <SettingsProvider
            settings={settings}
            className={inter.variable}
            style={Object.entries(themeVariables).reduce((acc, [key, value]) => {
              acc[key] = value
              return acc
            }, {})}
          >
            {getLayout(<Component {...pageProps} />)}
          </SettingsProvider>
          <Toast />
        </UrqlProvider>
      </SessionProvider>
      <script
        dangerouslySetInnerHTML={{
          __html: `window.env =${JSON.stringify(environmentVariables)}`,
        }}
      ></script>

      {getEnv('NEXT_PUBLIC_SHOLDI_ENABLE_ANALYTICS') &&
        getEnv('NEXT_PUBLIC_SHOLDI_ENABLE_ANALYTICS') === 'true' &&
        settings?.matomoSiteId && (
          <>
            <noscript>
              {/* eslint-disable-next-line @next/next/no-img-element     */}
              <img
                loading="eager"
                referrerPolicy="no-referrer-when-downgrade"
                src="https://insight.sholdi.ba/js/sholdi.php?idsite=1&amp;rec=1"
                style={{ border: 0 }}
                alt=""
              />
            </noscript>

            <Script
              id="sholdi-insight-tag-manager"
              src={`${getEnv('NEXT_PUBLIC_MATOMO_URL')}/js/sholdi.php`}
              strategy="worker"
              onReady={() => {
                import('@sholdi/shared/lib/matomo').then(({ init }) => {
                  init({
                    url: getEnv('NEXT_PUBLIC_MATOMO_URL'),
                    siteId: settings.matomoSiteId,
                  })
                })
              }}
            />
          </>
        )}
    </>
  )
}

MyApp.propTypes = {
  session: PropTypes.shape({}),
  Component: PropTypes.func,
  pageProps: PropTypes.shape({
    lng: PropTypes.string,
    lngDict: PropTypes.shape({}),
    requiresAuth: PropTypes.bool,
    inaccessibleForAuthenticatedUsers: PropTypes.bool,
    settings: PropTypes.shape({
      currency: PropTypes.shape({
        code: PropTypes.string,
        symbol: PropTypes.string,
      }),
      appUrl: PropTypes.string,
    }),
    environmentVariables: PropTypes.shape({}),
  }),
  commonData: PropTypes.shape({
    tenant: PropTypes.shape({
      defaults: PropTypes.shape({
        currency: PropTypes.string,
      }),
    }),
    mainNavigation: PropTypes.arrayOf(shape()),
    locale: PropTypes.shape({}),
    localeResource: PropTypes.shape({}),
  }),
}

export default MyApp
