/**
 * Set environment variables in window.env
 * @param {Object.<string, *>} variables - Environment variables to set
 * @returns {void}
 */
module.exports = variables => {
  if (!variables || typeof window === 'undefined') {
    return
  }

  if (!window.env) {
    const result = Object.freeze(variables)
    Object.defineProperty(window, 'env', {
      value: result,
      writable: false,
    })
  }
}
