import memoize from 'lodash.memoize'

export const formatPrice = memoize(priceInCents => {
  const formatedPrice = Number(priceInCents) / 100

  if (isNaN(formatedPrice)) {
    return 0
  }
  return Number(formatedPrice).toFixed(2)
})
