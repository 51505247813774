import AUTH_SECTIONS from '../../constants/authSections'
import memoize from 'lodash.memoize'

const getAuthSection = pathname =>
  Object.keys(AUTH_SECTIONS).reduce((acc, key) => {
    if (pathname.startsWith('/' + AUTH_SECTIONS[key].path)) {
      acc = key
    }
    return acc
  }, 'public')

export default memoize(getAuthSection)
