/**
 * Gets an environment variable from various contexts
 * @param {string} key - The environment variable key to look up
 * @param {*} fallback - The default value if the key is not found
 * @returns {*} The environment variable value or fallback
 */
const getEnv = (key, fallback = '') => {
  // Type check for key
  if (typeof key !== 'string') {
    throw new TypeError('Environment variable key must be a string')
  }

  // Check Node.js environment first
  const hasProcessEnv = typeof process !== 'undefined' && process.env
  if (hasProcessEnv && process.env[key]) {
    return process.env[key]
  }

  // If we're not in Node.js (i.e., in browser)
  if (typeof window !== 'undefined') {
    return window?.env?.[key] ?? fallback
  }

  return fallback
}

module.exports = getEnv
